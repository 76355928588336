<template>
<div>

  <v-card elevation="0"   style="padding: 15px 30px;height: 100%;" >
  </v-card>

  <vcl-facebook  style="margin: 20px" v-if="!pageloading"></vcl-facebook>
  <v-card v-else elevation="0" style="padding: 15px 30px;height: 100%;" >
    <v-row v-if="$store.getters.auth.loggedIn">
      <v-col cols="6" color="border-bottom: solid 1px #ccc">
        <router-link to="/profile" class="rlink"><v-icon >mdi-close</v-icon><span>برگشت به قبل</span></router-link>
      </v-col>
      <v-col cols="2">

      </v-col>
      <v-col cols="4">

        <p @click="dialog3 = true" class="mb-0 mt-0" >
          <v-icon>mdi-logout-variant</v-icon>&nbsp;
          <span>خروج</span>
        </p>
      </v-col>

      <v-card
          class="mx-auto"
          style="width: 100%;text-align: right;"
          v-if="viewcard"
      >
        <v-card-subtitle style="color:#0e0e0e;background-color:#6a737d; font-weight: bolder;text-align: right;width: 100%;">
          <span color="red" >کارت ما</span>
        </v-card-subtitle>

        <v-card-actions style="text-align: right;flex-direction: row-reverse !important;" >

          <v-spacer></v-spacer>
          <v-row>

            <v-col cols="12">
              <p class="text-right">
                شماره کارتی که باید بزنید
              </p>
            </v-col><v-col cols="12">
            <v-text-field v-model="cardmaghsad" type="number" outlined enabled="false"  ></v-text-field>
          </v-col>
            <v-col cols="12">
              <p class="text-right">
                نام شخص صاحب کارت
              </p>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="names" outlined enabled="false"  ></v-text-field>
            </v-col>
            <v-col cols="12">
              <p class="text-right">
                ده دقیقه بعد واریز اگر تایید نشد یک پیامک به همین شماره بزنید تا تایید شود
              </p></v-col>
            <v-col cols="12">
              <v-text-field v-model="tell" type="number" outlined enabled="false"  ></v-text-field><br>
            </v-col>
          </v-row>


        </v-card-actions>

      </v-card>
      <v-divider style="margin:5px;"></v-divider>
      <v-card
          class="mx-auto"
          style="width: 100%;text-align: justify-all;"
          v-if="cardvisible"
      >
        <v-card-subtitle style="color:#0e0e0e;background-color:#2077d3; font-weight: bolder;text-align: justify-all;width: 100%;">
          <span color="red" >ثبت مبلغ و انتخاب کارت بانکی شما</span>
        </v-card-subtitle>

        <v-card-actions >

          <v-spacer></v-spacer>
          <div  style="justify-content: space-between;flex-direction: column;font-weight: bold;text-align: justify "  >
            <span class="font-medium"> شما باید یک کارت از لیست زیر انتخاب کنید و مبلغ را دقیقا با همان کارت انتخاب شده به ما بزنید </span> <br>
            <br>
            <span class="font-medium">دقت داشته باشید که مبلغی که وارد میکنید به تومان است اگر در اینجا به ریال بزنید و برای ما به تومان واریز کنید تایید نمیشود </span> <br>
            <br>
            <span class="font-medium"> یعنی اینکه اگر میخواهید هشتاد هزار تومان واریز کنید باید بنویسید 80000 نه اینکه بنویسید 800000 بنویسید </span> <br>
            <br>
            <span class="font-medium"> همیشه قبل واریز باید از این قسمت کارت جدید بگیرید حتی اگر صد روز پشت هم کارتی که به شما داده بود یکی باشد </span> <br>
            <br>
            <v-row>

              <v-col cols="12">
                <div style="color: red" class="text-sm text-muted mb-2"> کارتی که میخواهید از آن به ما واریز کنید :</div>
                <v-autocomplete
                    v-model="card"
                    :items="items_card"
                    item-text=sh_card
                    item-value=sh_card
                    outlined
                    dense
                    label=" کارت شما *"
                ></v-autocomplete>
                <div style="color: red" class="text-sm text-muted mb-2"> مبلغی که به تومان برای ما میزنید :</div>
                <v-text-field v-model="price" type="number" outlined label="مبلغ "  ></v-text-field>
              </v-col>

            </v-row>

            <v-btn  @click="getcardma" :loading="loading" depressed color="#e3a232" height="50" style="width: 100%"> نمایش کارتی که باید به آن واریز کنید </v-btn>

          </div>

        </v-card-actions>

      </v-card>





      <v-divider style="margin:5px;"></v-divider>




    </v-row>
  </v-card>

  <vcl-facebook style="margin: 20px"  v-if="!pageloading"></vcl-facebook>
  <v-row v-else justify="center">
    <v-dialog v-model="dialog3" persistent  max-width="390" >

      <v-card>
        <v-card-title style="font-size: 16px;word-break: normal">
         آیا برای خروج از حساب کاربری مطمن هستید؟
        </v-card-title>
        <v-card-text>این عملیات غیر قابل بازگشت میباشد و برای دسترسی دوبار باید لاگین کنید</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  color="green darken-1" text @click="dialog3 = false">
            خیر منصرف شده
          </v-btn>
          <v-btn color="red darken-1" text @click="logoutApp">
            بله مطمنم
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>


</div>

</template>
<script>
  import {mapGetters} from 'vuex';
  import {mapMutations} from 'vuex';
  import axios from "axios";
  import { VclFacebook, VclInstagram } from 'vue-content-loading';
  import html2canvas from 'html2canvas';


  export default {
    name: 'card',
    components:{
      VclFacebook,
      VclInstagram,
      mapGetters,mapMutations},
    data() {
      return {
        card:"",
        price:"",
        listcardmavatell:"",
        items_card:[],
        loading:false,
        pageloading:false,
        cardvisible:true,
        dialog2:false,
        dialog3:false,
        cardmaghsad:"",
        names:"",
        tell:"",
        viewcard:false,
      }
    },
    mounted() {
      let self = this;
      this.getcard();
      this.viewcard=false;
      //self.pageloading=true;

    },
    computed:{
      ...mapGetters(['auth','appToken','baseUrl','baseUrlproblem']),

    },

    methods:{
      ...mapMutations(['logout']),
      copy() {
        this.$refs.clone.focus();
        document.execCommand('copy');
        this.helperToast({
          show:true,
          text:"آدرس کیف پول ما کپی شد",
          color:'green'
        });

      },
      isValidCard: function (card) {

        //address = address.replace('0x','');
        //console.log(address);
        if (card.length==16) {
          return true;
        }
        else
          return false;

      },
      getcard() {

        let self = this;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        let formData = new FormData();
        formData.append("token", self.appToken);
        formData.append("code_shakhs", self.$store.getters.auth.data.user_id);

          axios.post(this.baseUrl+'getcard.aspx', formData, config)
              .then(function (r) {
                //console.log(r.data);
                if(r.data){
                  self.items_card=r.data;
                  self.pageloading=true;
                }
                else
                {
                  self.helperToast({
                    show:true,
                    text:"متاسفانه کارتی یافت نشد",
                    color:'red'
                  });
                  self.pageloading=true;
                }
              });



      },
      getcardma(){
        let self = this;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        let formData = new FormData();
        formData.append("token", self.appToken);
        formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
        formData.append("cardmabda", self.card);
        formData.append("mablagh", self.price);

          if (self.card.length == 16) {
            if(self.price >= 100000 && self.price%10000 != 0) {
            axios.post(this.baseUrl + '/getcardma.aspx', formData, config)
                .then(function (r) {
                  //console.log(r.data);
                  if (r.data.data.result == "1") {
                    self.cardmaghsad = r.data.data.cardma;
                    self.names = r.data.data.name;
                    self.tell = r.data.data.tell;
                    self.viewcard = true;
                    // const screenshot = async () => {
                    //   const canvas = await html2canvas(document.querySelector("#app"));
                    //   const img = canvas.toDataURL("image/png");
                    //   const link = document.createElement('a');
                    //   link.download = 'screenshot10.png';
                    //   link.href = img;
                    //   link.click();
                    // }
                    // screenshot();

                  }
                  else if (r.data.data.result == "2") {
                    self.helperToast({
                      show: true,
                      text: "شما قبلا یک کارت برای واریز ثبت کردید باید آن را واریز کنید",
                      color: 'red'
                    });

                  }
                  else {
                    self.helperToast({
                      show: true,
                      text: "فعلا امکان دریافت کارت وجود ندارد بعدا مراجعه کنید",
                      color: 'red'
                    });
                    self.viewcard = false;
                  }
                  self.pageloading = true;
                });
          } else
          {
            self.helperToast({
              show: true,
              text: "به دلیل کم کردن تعداد تراکنش ها، کمتر از صد هزار تومان و مبالغ رند نمیتوانید هدیه دهید",
              color: 'red'
            });

          }
          }
        else
        {
          self.helperToast({
          show: true,
          text: "یک کارت از لیست کارتهایتان انتخاب کنید",
          color: 'red'
        });

        }

      },
      logoutApp(){
        this.logout();
        this.$router.push({path:'/'})

      },

    },
    watch:{
     /* file_list(v){
        this.param.avatar = URL.createObjectURL(v);
        this.saveAvatar();
      }*/
    }
  }
</script>

<style>
  .header-r{
    text-align: center;
  }
  .header-l p{

  }
  .header-l p span.subtitle{
    font-weight: bold;
    font-size: 13px;
  }

  #gallery {
    margin-top: 10px;
  }
  .fm-preview-img{
    width:  100%;
    height: 100px;
    border-radius: 5px;
    position: relative;
    vertical-align: middle;
    margin-bottom: 5px;
    background: #DDD;
    display: flex;
    align-items: center;
    padding: 10px;
    overflow: hidden;
  }
  .fm-preview-img img{
    max-width: 100px;
    height: 100%;
    max-height: 100px;
    border-radius: 5px;
    margin-left: 5px;
  }
  .text-error{
    color: coral;
    font-weight: bold;
    line-height: 1;
    font-size: 13px;
  }

  .fileBselectRows{
    width: 100%;
    border:dashed 2px #0084ff;
    border-radius: 5px;
    height: 105px;
    overflow: hidden;
    position: relative;
    z-index: 98;
    vertical-align: middle;
    display: inline-block;
    background: #fff;
  }
  .fileBrows input{
    width: 100%;
    height: 100%;
    border:solid 5px;
    opacity: 0;
    cursor: pointer;
    display: block;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
  }
  .fileBrows span{
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    z-index: 9;
    position: absolute;
    display: block;
    color: #0084ff;
    left: 0;
    top:calc(30% - 20px);
  }
  .fileBrows span i{
    font-size: 30px;
    font-style: normal;
  }

</style>







